import { HeadFC, graphql } from "gatsby";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation as NavigateSwiper } from "swiper";
import Footer from "../../components/footer/footer";
import Navigation from "../../components/navigation/navigation";
import SEO from "../../components/seo/seo";
import StylesComponent from "../../components/styles/styles-component";
import { numericOrdinal } from "..";

// CSS
import "../../sass/page-specific/event-styles.scss";
import "../../sass/page-specific/homepage-styles.scss";
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import PackageEnquiry from "../../components/package-enquiry/package-enquiry";
import SomethingElseCta from "../../components/ctas/something-else-cta";
import CookiesConsentNotice from "../../components/cookies-consent/cookies-consent";

interface pageProps {
   allContentfulFeaturedEvents: {
     edges: {
       node: {
         eventName: string,
         location: string,
         slug: string,
         startTime: string,
         endTime: string,
         eventImage: {
           url: string
         }
       }
     }[]
   }
 }

const Events: React.FC<{ data: pageProps }> = ({ data }): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   const featured_events = data.allContentfulFeaturedEvents.edges;

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(https://images.ctfassets.net/welt89o8b674/4QqQtOJicDdYsfSKFYCjtG/3c3067fc9ae839b4eb19794bf8e32b95/horse-racing-event-2.jpg)`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>Events</h1>
                  <p className="secondary-text">What's happening in the world of events?</p>

                  <br/>

                  <a href="#package-enquiry" className="standard-button orange">Make enquiry</a>
               </div>
            </div>
         </div>

         <div className="standard-event-outer-container">
            <div className="standard-event-inner-container container-width">
               <h2>Featured events</h2>

               <br/>

               <div className="events-carrousel-container">
                  <button className="carrousel-button" id="prev-slide"/>

                  <Swiper
                     modules={[Autoplay, NavigateSwiper]}
                     navigation={{
                        prevEl: "#prev-slide",
                        nextEl: "#next-slide"
                     }}
                     allowTouchMove={false}
                     autoplay={{
                        delay: 3000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                     }}
                     preventClicks={false}
                     preventClicksPropagation={false}
                     breakpoints={{
                        1: {
                           slidesPerView: 3,
                           spaceBetween: 20,
                           allowTouchMove: true
                        },
                        820: {
                           slidesPerView: 2,
                           spaceBetween: 40
                        },
                        1200: {
                           slidesPerView: 3.02,
                           spaceBetween: 42
                        }
                     }}
                     loop={true}
                  >
                  {featured_events.map((event) => {
                     // Format date(s)
                     const start_date = event.node.startTime
                     const end_date = event.node.endTime
                     
                     let event_string: string = '';

                     // If event is one-day
                     if(start_date.substring(0, 10) === end_date.substring(0, 10)) {
                        event_string += numericOrdinal(parseInt(start_date.substring(8, 10))) + " "
                        event_string += new Date(start_date.substring(0, 10) + "T12:00:00+00:00").toLocaleDateString("en-GB", { month: 'long', year: 'numeric' })
                     } else {
                        // Multi-day event
                        event_string += numericOrdinal(parseInt(start_date.substring(8, 10))) + " "
                        event_string += new Date(start_date.substring(0, 10) + "T12:00:00+00:00").toLocaleDateString("en-GB", { month: 'long' }) + " - "
                        event_string += numericOrdinal(parseInt(end_date.substring(8, 10))) + " "
                        event_string += new Date(end_date.substring(0, 10) + "T12:00:00+00:00").toLocaleDateString("en-GB", { month: 'long', year: 'numeric' })
                     }

                     return (
                        <SwiperSlide>
                        <a className="featured-event-card white" href={"/events/" + event.node.slug}>
                           <div className="featured-event-image" style={{backgroundImage: `url(${event.node.eventImage.url})`}} />

                           <h3>{event.node.eventName}</h3>

                           <a href={"/events/" + event.node.slug} className="event-information location">{event.node.location}</a>
                           <a href={"/events/" + event.node.slug} className="event-information date">{event_string}</a>

                           <a href={"/events/" + event.node.slug} className="find-out-more">Find out more</a>
                        </a>
                        </SwiperSlide>
                     )
                  })}
                  </Swiper>

                  <button className="carrousel-button" id="next-slide"/>
               </div>
            </div>
         </div>

         <PackageEnquiry
            eventImage="https://images.ctfassets.net/welt89o8b674/4HInrtNWZ1HvjloK4pMgSk/b8c5c651196a378465c0e6a7300b0554/football-stadium-event.jpg"
            title="Make an event request"
            subheading="We'll get you the best tickets to your favourite events"
            eventPlaceholder="Peter Kay Live at O2 Arena, London"
         />

         <SomethingElseCta
            title="Prefer to give us a call?"
         />

         <Footer/>
      </React.Fragment>
   )
}

export const Head: HeadFC = () => (
   <SEO
      title="Events | Prestige VIP"
      metaDescription="Get the best tickets to your favourite events across the globe. Experience luxury and VIP like nowhere else with Prestige VIP."
      slug="/events"
   />
)

export const pageQuery = graphql`
  query relatedPages {
    allContentfulFeaturedEvents ( filter: { active: { eq: true } } ) {
      edges {
        node {
          eventName
          location
          slug
          startTime
          endTime
          eventImage {
            url
          }
        }
      }
    }
  }
`

export default Events